<template>
    <div style="background-color: black; position: fixed; top: 0; left: 0; right: 0; bottom: 0;">
        <!--    <img alt="Vue logo" src="./assets/logo.png">-->
        <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
        <!--        <el-container>-->
        <!--            <el-header>-->
        <!--                t-->
        <!--            </el-header>-->
        <!--            <el-main>-->
        <!--                z-->
        <!--            </el-main>-->
        <!--            <el-footer>-->
        <!--                w-->
        <!--            </el-footer>-->
        <!--        </el-container>-->

        <MyTmp/>
        <div class="footer">
            <p>
                <a :href="beianUrl" target="_blank" title="点击查看备案信息">备案号：粤ICP备2024225679号-1</a>
            </p>
            <p>
                服务器只有1.7G可用内存,Nacos最低可运行内存800M,通过swap磁盘才勉强跑起整套微服务,整体响应会比较慢
            </p>
        </div>
    </div>
</template>

<script>
    import MyTmp from './components/MyTmp.vue'

    export default {
        name: 'App',
        components: {
            MyTmp
        },
        data() {
            return {
                beianUrl: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2024225679'
            };
        },
    }
</script>

<style>
    a {
        /*text-decoration: none; !* 移除下划线 *!*/
        color: inherit; /* 使用文本颜色继承父元素的颜色 */
    }
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        background-color: rgba(192, 192, 192, 0.5); /* 使用RGBA颜色值设置半透明的银色背景 */
        border-top: 1px solid #e5e5e5;
        padding: 10px 0;
        color: white;
        font-size: larger;
    }
</style>
