<template>
    <div class="container">
<!--        <img src="@/assets/logo.png">-->
<!--        <el-row type="flex" justify="center">-->
<!--            <el-col>-->
<!--                <transition>-->
<!--                    <div v-show="show2" class="transition-box" @click="jumpToMall">查看示例1</div>-->
<!--                </transition>-->
<!--            </el-col>-->
<!--            <el-col>-->
<!--                <transition>-->
<!--                    <div v-show="show2" class="transition-box" @click="jumToErp">查看示例2</div>-->
<!--                </transition>-->
<!--            </el-col>-->
<!--        </el-row>-->


        <transition name="el-zoom-in-center">
            <div v-show="show2" class="transition-box" @click="jumpToMall">Mall Demo</div>
        </transition>
        <transition name="el-zoom-in-bottom">
            <div v-show="show2" class="transition-box" @click="jumToErp">Erp Demo</div>
        </transition>
<!--        <el-row type="flex" justify="center">-->
<!--            <transition>-->
<!--                <div v-show="show2" class="transition-box">ccccccccccccc</div>-->
<!--            </transition>-->
<!--        </el-row>-->
    </div>
</template>

<script>
    export default {
        methods: {
            jumpToMall() {
                window.location.href = 'https://wave2x.top/mall';
            },
            jumToErp() {
                window.location.href = 'https://wave2x.top/erp';
            }
        },
        mounted() {
            setTimeout(() => {
                this.show2 = true;
            }, 1000);
        },
        data: () => ({
            show2: false
        }),
        name: 'MyTmp'
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    /*.transition-box {*/
    /*    margin-bottom: 10px;*/
    /*    width: 30%;*/
    /*    height: 30%;*/
    /*    vertical-align: center;*/
    /*    align-items: center;*/
    /*    border-radius: 4px;*/
    /*    background-color: #d8f3f0;*/
    /*    text-align: center;*/
    /*    color: #89bb8e;*/
    /*    padding: 40px 20px;*/
    /*    box-sizing: border-box;*/
    /*    margin-right: 20px;*/
    /*    font-size: larger;*/
    /*    font-style: inherit;*/
    /*}*/
    .transition-box {
        margin-bottom: 10px;
        /*width: 30%;*/
        /*height: 30%;*/
        width: 30vw;
        height: auto;
        vertical-align: center;
        align-items: center;
        border-radius: 4px;
        background-color: #000000;
        text-align: center;
        color: silver; /* 设置字体颜色为银色 */
        padding: 40px 20px;
        box-sizing: border-box;
        margin-right: 10vw;
        position: relative; /* 添加相对定位 */
        border: 2px solid silver; /* 添加银色边框 */
        font-weight: bold; /* 设置字体加粗 */
        font-size: 7vw;
    }
    .transition-box:hover {
        color: red; /* 鼠标悬浮时的字体颜色 */
        border: 6px solid orangered; /* 添加银色边框 */
    }
    @media screen and (max-width: 844px) {
        .transition-box {
            /*width: 100%; !* 在小屏幕上宽度为100% *!*/
            height: auto; /* 高度自适应 */
            font-size: 5vw; /* 调整字体大小 */
        }
    }
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* 设置容器高度为视口高度 */
        width: 110vw;
    }
</style>
